<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Kantor Operasional
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama Kantor"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Kantor"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 45 }"
                    >
                      <b-form-input
                        v-model="name"
                        placeholder="Masukkan Nama Kantor"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="PIC"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`PIC`"
                    >
                      <v-select
                        class="w-100"
                        v-model="picId"
                        placeholder="Pilih PIC"
                        :label="'name'"
                        @search="(search) => search.length > 0 ? onSearchPic(search) : ''"
                        :reduce="staff => staff.id"
                        :options="listPic"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Lokasi"
                      rules="required"
                    >
                      <b-button
                        variant="warning"
                        class="btn-icon d-flex align-items-center"
                        v-b-modal.modalMap
                      >
                        <span class="k-location h-100 font-bold text-10 align-middle mx-[5px]"/>
                        {{addressName || 'klik untuk memilih lokasi'}}
                        <span class="k-arrow-right-2 h-100 font-bold text-10 align-middle mx-[5px]"/>
                      </b-button>
                      <small class="text-danger">{{
                        errors[0] || submitErrors.province_id
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Detail Alamat"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Detail Alamat"
                      rules="required|min:5"
                    >
                      <b-form-textarea
                        placeholder="Masukkan alamat lengkap kamu
contoh : jl. no desa, rt, rw"
                        v-model="address"
                        :state="
                          errors.length > 0 || submitErrors.address ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.address
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary"
            :disabled="invalid || loadingSubmit || (!lat && !lng)"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalMap @selected="updateDataLocation" :initialPlaces="initialPlaces"/>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import { alertError, alertSuccess } from '@toast'
import ModalMap from './ModalMap.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalMap,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      alertError,
      alertSuccess,
      detailOffice: {},

      name: '',
      address: '',
      lat: null,
      lng: null,
      addressName: '',
      picId: null,

      dataLocation: null,
      initialPlaces: {
        lat: -7.281931215395999,
        lng: 109.46630203862301,
      },
      listPic: [],
    }
  },
  async mounted() {
    await this.getDetailOffice()
    await this.getListPic('')
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        office_name: this.name,
        lat: this.lat,
        lng: this.lng,
        address: this.address,
        staff_id: Number.isInteger(this.picId) ? this.picId : this.detailOffice.pic_id,
      }

      const url = `v1/operational_offices/${this.id}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push('/operational-office')
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
          this.loadingSubmit = false

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getDetailOffice() {
      const url = `v1/operational_offices/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailOffice = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListPic(keyword) {
      this.loading = true
      const params = `keyword=${keyword}&limit=20`
      const url = `v1/staffs?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listPic = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPic: _.debounce((search, it) => {
      it.getListPic(search)
    }, 500),
    onSearchPic(search) {
      this.searchPic(search, this)
    },
    updateDataLocation(data) {
      this.addressName = data.address
      this.lat = data.lat
      this.lng = data.lng

      this.initialPlaces = {
        lat: data.lat,
        lng: data.lng,
      }
    },
    loadForm() {
      this.name = this.detailOffice.office_name
      this.address = this.detailOffice.address
      this.picId = this.detailOffice.pic_name
      this.lat = this.detailOffice.lat
      this.lng = this.detailOffice.lng
      this.initialPlaces = {
        lat: this.detailOffice.lat,
        lng: this.detailOffice.lng,
      }

      this.$geocoder.send({
        lat: this.detailOffice.lat,
        lng: this.detailOffice.lng,
      }, response => {
        const { results } = response
        if (!results.length) return

        const data = results[0]
        const addressParts = data.formatted_address?.split(',')
        this.addressName = addressParts?.length > 0 ? addressParts[0] : this.address
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
</style>

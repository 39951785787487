var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Kantor Operasional ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Kantor","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama Kantor","rules":{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 45 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Masukkan Nama Kantor","state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"PIC","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"PIC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih PIC","label":'name',"reduce":function (staff) { return staff.id; },"options":_vm.listPic},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchPic(search) : ''; }},model:{value:(_vm.picId),callback:function ($$v) {_vm.picId=$$v},expression:"picId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Lokasi","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalMap",modifiers:{"modalMap":true}}],staticClass:"btn-icon d-flex align-items-center",attrs:{"variant":"warning"}},[_c('span',{staticClass:"k-location h-100 font-bold text-10 align-middle mx-[5px]"}),_vm._v(" "+_vm._s(_vm.addressName || 'klik untuk memilih lokasi')+" "),_c('span',{staticClass:"k-arrow-right-2 h-100 font-bold text-10 align-middle mx-[5px]"})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.province_id))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Detail Alamat","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Detail Alamat","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Masukkan alamat lengkap kamu\ncontoh : jl. no desa, rt, rw","state":errors.length > 0 || _vm.submitErrors.address ? false : null},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary",attrs:{"disabled":invalid || _vm.loadingSubmit || (!_vm.lat && !_vm.lng)},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)])]}}])})],1),_c('ModalMap',{attrs:{"initialPlaces":_vm.initialPlaces},on:{"selected":_vm.updateDataLocation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }